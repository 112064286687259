<template>
  <div class="news">
    <XwdtPageLayout activeCode="xinwendongtai">
      <template v-slot:search>
        <div class="div_chooseBox">
          <el-select v-model="year" value-key="" placeholder="年份选择" clearable filterable>
            <el-option
              v-for="item in years"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input v-model="search" placeholder="搜索。。。" size="normal" clearable></el-input>
          <el-button type="primary" size="default" @click="getNotices">搜索</el-button>
        </div>
      </template>

      <template v-slot:list>
        <div class="notice">
          <div class="news_list2">
            <ul>
              <li v-for="notice in notices" :key="notice.id">
                <NoticeCard :notice="notice" @click="goDetail(notice)" />
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-slot:pagination>
        <el-pagination
          class="div_page"
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :total="+total"
          :current-page="+currentPage"
          :page-size="pageSize"
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </template>
    </XwdtPageLayout>
  </div>
</template>

<script>
import XwdtPageLayout from './components/XwdtPageLayout.vue'
import TabPageLayout from './components/TabPageLayout.vue'
import NoticeCard from './components/NoticeCard.vue'

import portalApi from '../../../lvya-common/src/api/eos/portal'
import { mapState } from 'vuex'
import { years } from './utils/useYearOptions'

export default {
  components: { XwdtPageLayout, NoticeCard, TabPageLayout },
  data() {
    return {
      notices: [],
      total: 0,
      currentPage: 1,
      pageSize: 5,
      typeId: undefined,
      search: '',
      year: '',
      years,
    }
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    navTabs() {
      return (
        this.nav
          ?.find((item) => item.code === 'xinwendongtai')
          ?.stypes?.map((nav) => ({
            id: nav.id,
            name: nav.title,
            path: nav.tzurl,
          })) || []
      )
    },
    activeTabId() {
      return this.navTabs?.find((item) => item.path === this.$route.path)?.id
    },
    activeCode() {
      return 'xinwendongtai'
    },
  },
  methods: {
    goDetail(notice) {
      console.log(notice, 'notice')
      this.$router.push({
        path: '/portalDetails',
        query: {
          id: notice.id,
          activeCode: this.activeCode,
        },
      })
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.getNotices()
    },
    async getNotices() {
      let notices = await portalApi.getArtsByPage({
        noSchoolId: true,
        schoolId: '7',
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        typeId: this.activeTabId,
        applyState: 'Y',
        isContent: 'Y',
        search: this.search,
        year: this.year || undefined,
      })
      console.log(notices)
      this.notices = notices.list
      this.total = notices.total
    },
  },
  mounted() {
    this.portalSchoolInfo = JSON.parse(
      localStorage.getItem('portalSchoolInfo')
    )

    this.getNotices()
  },
}
</script>

<style lang="less" scoped>
@import "./style/common.css";
@import "./style/global.css";
@import "./style/news.css";

/deep/.el-button.el-button--primary {
  background-color: #7e0001;
  border-color: #7e0001;
}
</style>
