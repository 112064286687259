<template>
  <a @click.stop="$emit('click')">
    <div class="div_l">
      <!-- <span>08-30</span> -->
      <span>{{month}}月{{day}}日</span>
      <span>{{year}}年</span>
    </div>
    <div class="div_r">
      <h3>{{notice.title}}</h3>
      <p>
        {{notice.des}}
      </p>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    notice: { type: Object, default: () => {} }
  },
  computed: {
    year() {
      return new Date(this.notice.ctime).getFullYear()
    },
    month() {
      return new Date(this.notice.ctime).getMonth() + 1
    },
    day() {
      return new Date(this.notice.ctime).getDate()
    }
  }
}
</script>

<style lang="less" scoped>
@import "../style/common.css";
@import "../style/global.css";
@import "../style/news.css";
</style>
