// 创建年份选择 select 的选项
function createYearOptions() {
  const year = new Date().getFullYear()
  const before10year = Array.from({ length: 20 }, (_, i) => year - i)
  const years = [...before10year]
  return years.map((year) => ({
    id: year,
    label: year,
    value: `${year}`,
  }))
}

const years = createYearOptions()

export { years }
